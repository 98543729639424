<div >
  <public-header></public-header>
  <!-- <div class="login">
  <router-outlet></router-outlet>
</div> -->
  <div class="fixed_deal_img dealContainer" *ngIf="advertismentData && !dealData">
    <!-- <div class="timer_count">{{timeLeft}}</div> -->
    <figure class="figure">
      <a href="{{advertismentData.link}}" target="_blank">
        <img [src]="advertismentData.attchment[0].filePath" class="dynamic_ad" alt="...">
      </a>
    </figure>
    <ng-container *ngIf="isEnableSkip">
      <div class="loading_text" (click)="skipDeal()" style="cursor: pointer;">
        skip
        <!-- <p> Please wait.. <br />
          We're doing our best to bring you the best offers out there. </p> -->
      </div>
    </ng-container>
  </div>
  <div class="container dealContainer" *ngIf="dealData">
    <div class="card">
      <div class="card-header">
        <h4 style="max-width: 76%;">{{dealData.offerTitle}}</h4>
        <h6 *ngIf="coupon">coupon: {{coupon}}</h6>
        <span>{{dealData.fromDate | date :'dd-MMM-yy h:mm:ss'}}</span> - <span>{{dealData.toDate | date :'dd-MMM-yy
          h:mm:ss'}}</span>
        <span class="poabs badge">{{dealData.category}}</span>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <figure class="figure">
              <img [src]="dealData.dealImg.filePath" class="figure-img img-fluid rounded" alt="...">
            </figure>
            <ng-container *ngIf="dealData?.dealContentTarget">
              <builder-component model="{{dealData.dealContentTarget}}" (click)="clickBuilderContent()"></builder-component>
            </ng-container>
            <ng-container *ngIf="!dealData?.dealContentTarget">
              <div [innerHTML]="dealContent"></div>
            </ng-container>
          </div>
        </div>
        <!-- <div class="row" *ngIf="dealData.vedInstr">
        <div class="col-md-12">
          <h3>Video Instructions</h3>
          <div [innerHTML]="dealData.vedInstr"></div>
        </div>
      </div> -->
        <div class="row">
          <div class="col-md-12">
            <hr>
          </div>
        </div>

        <!-- <div class="row">
        <div class="col-md-12">
          <h3>Address</h3>
          <address>
            {{dealData.location.address}}
          </address>
        </div>
      </div> -->

      </div>
    </div>
  </div>
</div>


<ng-template #mymodal let-modal>
  <!-- <div class="modal-header">
<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button> 
  </div>-->
  <div class="modal-body" *ngIf="dealData">
    <div class="row">
      <div class="col-md-12 text-center">
        <img [src]="dealData.dealImg.filePath" class="figure-img img-fluid rounded" alt="...">
        <h6 class="modal-title" id="modal-basic-title">Enter your email to avail the Deal / Offer</h6>
      </div>
    </div>

    <div class="row" *ngIf="!result && !result.message">
      <div class="col-md-8">
        <div class="form-group required">
          <label></label>
          <input type="email" class="form-control" placeholder="Enter email" [(ngModel)]="email">
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn btn-success my-4 btn-block" (click)="submit()" [disabled]="!email">Submit</button>
      </div>
    </div>
    <div class="row" *ngIf="result && result.message">
      <div class="col-md-12 text-center">
        <span style="font-size: 14px; color: green">{{result.message}}</span> &nbsp;&nbsp;
        <button type="button" class="btn btn-danger btn-sm" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">Close</span>
        </button>
      </div>
    </div>

  </div>

</ng-template>

<ngx-spinner bdColor="rgba(253,254,255,0.8)" size="medium" color="#0c62db" type="ball-clip-rotate-pulse"
  [fullScreen]="true">
  <p style="color: #007bff"> Please wait.. We're doing our best to bring you the best offers out there. </p>
</ngx-spinner>