<div class="common-layout" [ngClass]="{'sidebar-enable vertical-collpsed': isFolded}">
  <!-- sidebar-enable vertical-collpsed -->
  <app-header></app-header>

  <ng-sidebar-container class="page-container">

    <ng-sidebar [(opened)]="_opened" mode="push" position="left" [dock]="true" [dockedSize]="'70px'"
      [animate]="_animate">
      <!-- <ul metis-menu toggle="false">
        <li><a href="">Item 1</a></li>
        <li>
          <a href="#" class="has-arrow">Menu 1</a>
          <ul>
            <li><a href="#"> Item 3 sdsds sdsdsdsdsd</a></li>
            <li><a href="#"> Item 4 sd dsdsd sdsd</a></li>
          </ul>
        </li>
        <li>
          <a href="#" class="has-arrow"> Menu 2 </a>
          <ul>
            <li><a href=""> Item 5 </a></li>
            <li><a href=""> Item 6 </a></li>
            <li><a href=""> Item 7 </a></li>
            <li><a href=""> Item 8 </a></li>
          </ul>
        </li>
        <li><a href="">Item 2</a></li>
      </ul> -->

      <!-- ========== Left Sidebar Start ========== -->
      <!-- <app-sidenav></app-sidenav> -->
      <div class="vertical-menu">

        <div class="h-100">
          <perfect-scrollbar class="side-nav" [disabled]="isFolded">
          <!--- Sidemenu -->
          <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul metis-menu class="metismenu list-unstyled" id="side-menu">
              <li class="menu-title" key="t-menu">Menu</li>
              <ng-container *ngIf="role=='admin'">
                <li>
                  <a routerLink="dashboard" class="waves-effect">
                    <i class="bx bx-layout"></i>
                    <span>Dashboards</span>
                  </a>
                </li>

                <li>
                  <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="fas fa-database"></i>
                    <span>Masters</span>
                  </a>
                  <ul class="sub-menu" aria-expanded="false">
                    <li><a [routerLink]="['/masters']" routerLinkActive="router-link-active">Countries </a></li>
                    <li><a [routerLink]="['/masters/coins']" routerLinkActive="router-link-active">Crowns </a></li>
                    <li><a [routerLink]="['/masters/tax']" routerLinkActive="router-link-active">Tax </a></li>
                    <li><a [routerLink]="['/masters/cities']" routerLinkActive="router-link-active">Cities </a></li>
                    <li><a [routerLink]="['/masters/categories']" routerLinkActive="router-link-active">Categories </a>
                    </li>
                    <li><a [routerLink]="['/masters/influencer-categories']" routerLinkActive="router-link-active">Influencer Categories </a>
                    </li>
                    <li><a [routerLink]="['/masters/coin-settings']" routerLinkActive="router-link-active">Crown Settings
                      </a>
                    </li>
                    <li><a [routerLink]="['/masters/update-crown-count']" routerLinkActive="router-link-active">Update Crown Count
                    </a>
                    
                  </li>
                  <li><a [routerLink]="['/masters/update-vendor-category']" routerLinkActive="router-link-active">Update Vendor Category </a></li>
                  <li><a [routerLink]="['/masters/splash']" routerLinkActive="router-link-active">Splash Screen </a></li>
                  <li><a [routerLink]="['/masters/popup']" routerLinkActive="router-link-active">Popup Screen </a></li>
                  <li><a [routerLink]="['/masters/advertisment']" routerLinkActive="router-link-active">Advertisments </a></li>
                  

                  </ul>
                </li>

                <li>
                  <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="fas fa-user-friends"></i>
                    <span key="t-ecommerce">Vendors</span>
                  </a>
                  <ul class="sub-menu" aria-expanded="false">
                    <li><a [routerLink]="['/advertiser']" routerLinkActive="router-link-active">Vendors List </a></li>
                  </ul>
                </li>
                <li>
                  <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="fas fa-tags"></i>
                    <span>Deals</span>
                  </a>
                  <ul class="sub-menu" aria-expanded="false">
                    <li><a [routerLink]="['/deals']" routerLinkActive="router-link-active">Deals List </a></li>
                    <li><a [routerLink]="['/deals/expired-deals']" routerLinkActive="router-link-active">Expired Deals </a>
                    <li><a [routerLink]="['/deals/create-deal']" routerLinkActive="router-link-active">Create Deal </a>
                    </li>
                    <li><a [routerLink]="['/users-deal-comparision']" routerLinkActive="router-link-active">Deal Comparision</a>
                    </li>
                    <li><a [routerLink]="['/deals/vendor-deals']" routerLinkActive="router-link-active">Vendor Deals List </a></li>

                  </ul>
                </li>
                <li>
                  <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="fas fa-headset"></i>
                    <span>Questions</span>
                  </a>
                  <ul class="sub-menu" aria-expanded="false">
                    <li><a [routerLink]="['/questions']" routerLinkActive="router-link-active">Questions List </a></li>
                  </ul>
                </li>
                <li>
                  <a [routerLink]="['/users']" routerLinkActive="router-link-active" class="waves-effect">
                    <i class="fas fa-users"></i>
                    <span>Users</span>
                  </a>
                </li>
                <li>
                  <a [routerLink]="['/notification']" routerLinkActive="router-link-active" class="waves-effect">
                    <i class="far fa-bell"></i>
                    <span>Notification Message</span>
                  </a>

                </li>
                <li>
                  <a [routerLink]="['/reports/push-notifications']" routerLinkActive="router-link-active" class="waves-effect">
                    <i class="far fa-bell"></i>
                    <span>Push Notifications</span>
                  </a>

                </li>

                <li>
                  <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="far fa-file-alt"></i>
                    <span>Reports</span>
                  </a>
                  <ul class="sub-menu" aria-expanded="false">
                    <li>
                      <a [routerLink]="['/users']" [queryParams]="{ influencerType: 'premium' }" routerLinkActive="router-link-active">Premium Users</a>
                    </li>
                    <li><a [routerLink]="['/reports/']" routerLinkActive="router-link-active">Users Report </a></li>
                    <li><a [routerLink]="['/reports/transaction']" routerLinkActive="router-link-active">Transaction </a></li>
                    <li><a [routerLink]="['/reports/blocked-users/']" routerLinkActive="router-link-active">Blocked Users </a></li>
                    <li><a [routerLink]="['/reports/duplicate-users/']" routerLinkActive="router-link-active">Duplicate Users </a></li>
                    <li><a [routerLink]="['/reports/deals/']" routerLinkActive="router-link-active">Deals Report </a></li>
                    <!-- <li><a [routerLink]="['/reports/influencers-location/']" routerLinkActive="router-link-active">Inluencers Location </a></li> -->
                    <li><a [routerLink]="['/reports/location-wise-clicked-users/']" routerLinkActive="router-link-active">Location wise clicked users </a></li>
                    <li><a [routerLink]="['/reports/bulk-csv-upload']" routerLinkActive="router-link-active">Bulk CSV Upload</a></li>
                    <li><a [routerLink]="['/reports/fixed-bid-transactions']" routerLinkActive="router-link-active">Fixed Bid Transactions</a></li>
                  </ul>
                </li>
                <li>
                  <a href="javascript: void(0);" class="has-arrow waves-effect">
                    <i class="fas fa-database"></i>
                    <span>Offline Vendors</span>
                  </a>
                  <ul class="sub-menu" aria-expanded="false">
                    <li><a [routerLink]="['/masters/offline-vendors/offer-transactions']" routerLinkActive="router-link-active">Offer Transactions</a></li>
                    <li><a [routerLink]="['/masters/offline-vendors/list']" routerLinkActive="router-link-active">Vendors List </a></li>
                    <li><a [routerLink]="['/masters/offline-vendors/share-image']" routerLinkActive="router-link-active">Share Image</a></li>
                    <li><a [routerLink]="['/masters/offline-vendors/offline-vendor-approve-share-image']" routerLinkActive="router-link-active">Approve Share Image</a></li>
                    <!-- <li><a [routerLink]="['/masters/offline-vendors/create-vendor-image']" routerLinkActive="router-link-active">Create Vendor Image</a></li> -->
                    <li><a [routerLink]="['/masters/offline-vendors/create-vendor-image-list']" routerLinkActive="router-link-active">Create Vendor Image</a></li>
                    <li><a [routerLink]="['/masters/offline-vendors/approve-vendor-image']" routerLinkActive="router-link-active">Approve Vendor Image</a></li>
                    <li><a [routerLink]="['/masters/discount']" routerLinkActive="router-link-active">Discount/Offer </a></li>
                    <li><a [routerLink]="['/masters/offline-vendors/scan-content']" routerLinkActive="router-link-active">Scan Content</a></li>
                  </ul>
                </li>

              </ng-container>
              <ng-container *ngIf="role=='vendor'">
                <ng-container *ngIf="userInfo && userInfo.vendorType == 'online'">
                  <li>
                    <a routerLink="dashboard" class="waves-effect">
                      <i class="bx bx-layout"></i>
                      <span>Dashboards</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="has-arrow waves-effect">
                      <i class="bx bx-store"></i>
                      <span>Deals</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                      <li><a [routerLink]="['/deals']" routerLinkActive="router-link-active">Deals List </a></li>
                      <li><a [routerLink]="['/deals/create-deal']" routerLinkActive="router-link-active">Create Deal </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="has-arrow waves-effect">
                      <i class="far fa-file-alt"></i>
                      <span>Reports</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                      <li><a [routerLink]="['/reports/deals/']" routerLinkActive="router-link-active">Deals Report </a></li>
                      <li><a [routerLink]="['/reports/location-wise-clicked-users/']" routerLinkActive="router-link-active">Location wise clicked users </a></li>
                      <li><a [routerLink]="['/reports/deal-emails/']" routerLinkActive="router-link-active">Deal Wise Emails </a></li>
                    </ul>
                  </li>
                  
                </ng-container>
                <ng-container *ngIf="userInfo && userInfo.vendorType == 'offline'">
                  <li>
                    <a routerLink="dashboard" class="waves-effect">
                      <i class="bx bx-layout"></i>
                      <span>Dashboards</span>
                    </a>
                  </li>
                
                  <li>
                    <a href="javascript: void(0);" class="has-arrow waves-effect">
                      <i class="far fa-file-alt"></i>
                      <span>Reports</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                      <li><a [routerLink]="['/reports/deals/']" routerLinkActive="router-link-active">Deals Report </a></li>
                      <li><a [routerLink]="['/reports/location-wise-clicked-users/']" routerLinkActive="router-link-active">Location wise clicked users </a></li>
                      <li><a [routerLink]="['/reports/deal-emails/']" routerLinkActive="router-link-active">Deal Wise Emails </a></li>
                    </ul>
                  </li>
                  <li>
                    <a href="javascript: void(0);" class="has-arrow waves-effect">
                      <i class="fas fa-database"></i>
                      <span>Offline Vendors</span>
                    </a>
                    <ul class="sub-menu" aria-expanded="false">
                      <li><a [routerLink]="['/masters/offline-vendors/offer-transactions']" routerLinkActive="router-link-active">Offer Transactions</a></li>
                    </ul>
                  </li>
                </ng-container>
              </ng-container>

            </ul>
          </div>
          <!-- Sidebar -->
          </perfect-scrollbar>
        </div>
      </div>
      <!-- Left Sidebar End -->

    </ng-sidebar>

    <div ng-sidebar-content>
      <div class="page-content">
        <div class="container-fluid">
          <router-outlet></router-outlet>
        </div>
        <!-- <button (click)="_toggleOpened(false)" class="demo-header__toggle">Toggle sidebar</button> -->

      </div>

    </div>
  </ng-sidebar-container>
</div>
