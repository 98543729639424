<header id="page-topbar">
  <div class="navbar-header public-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="https://earnin.me/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo.png" alt="" height="17">
          </span>
        </a>

        <a href="https://earnin.me/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo.png" alt="" height="44">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo.png" alt="" height="42">
          </span>
        </a>
      </div>
    </div>


  </div>
</header>
