import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-expired-deal',
  templateUrl: './expired-deal.component.html',
  styleUrls: ['./expired-deal.component.css']
})
export class ExpiredDealComponent implements OnInit {
 
  constructor(
   
  ) {
    

  }




  ngOnInit(): void {
    
  }

  


}

