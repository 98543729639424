import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { AdminService } from 'src/app/shared/services/admin.service';
import {NgbModal, ModalDismissReasons, NgbModalConfig, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OnExecuteData, ReCaptchaV3Service } from "ng-recaptcha";
import { Subscription, timer } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.css']
})
export class PublicLayoutComponent implements OnInit {

  couponId: any;
  dealData: any;
  coupon: any;
  ipAddress:any;

  closeResult: string = '';
  @ViewChild('mymodal') public mymodal: TemplateRef<any> | undefined;
  email: any = '';
  result: any = '';
  recaptchaToken: string = '';
  advertismentData: any;
  timeLeft: number = 8;
  interval: any;
  subscribeTimer: any;
  dealContent: any;
  isUpdatedIp: boolean = false;
  isEnableSkip: boolean = false;
  
  constructor(
    private routerServices: Router,
    private activatedRouterServices: ActivatedRoute,
    private _adminService: AdminService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private recaptchaV3Service: ReCaptchaV3Service,
    private sanitizer: DomSanitizer
  ) { 
    config.backdrop = 'static';
		config.keyboard = false;
    
  }

  ngOnInit(): void {
    ////this.spinner.show();
    setTimeout(()=> {
      this.isEnableSkip = true;
    }, 5000);
    
    this.activatedRouterServices.params.subscribe((res: any) => {
      console.log("res", res.id);
      this.couponId = res.id;
      //this.getCouponDetails(this.couponId);
    });
    // setTimeout(() => {
    //   this.modalService.open(this.mymodal, {ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
    //     this.closeResult = `Closed with: ${result}`;
    //   }, (reason) => {
    //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    //   });
    // }, 500)
    
      
    
    this.recaptchaV3Service.execute('6LflJLIkAAAAAPLiBTt45b4SpEnG74AagRN85HWK').subscribe((token) => {
      
      this.recaptchaToken = token;
      /*
      if (this.recaptchaToken) {
        this.getCouponDetails(this.couponId);
      } else {
        alert("Invalid recaptcha. Please reload the page.")
      }
      */
      
      
    });

    this.recaptchaV3Service.onExecute.subscribe((data: OnExecuteData) => {
      if (data && data.token) {
        this.getAdvertisements();
        // this.startTimer();
        // setTimeout(()=> {
        //   this.getCouponDetails(this.couponId);
        //   this.pauseTimer();
        // }, 8000);
      }
      //this.handleRecaptchaExecute(data.action, data.token);
    });
    this.recaptchaV3Service.onExecuteError.subscribe((data1: any) => {
      console.log('-----------Error-------------');
      console.log(data1);
      alert("Invalid recaptcha. Please try again.")
    });

  }
  public ngOnDestroy() {
   
  }
  // handleRecaptchaExecute(action: any, token: any) {
  //   console.log('---------------data.token--------------');
  //   console.log(action, token);
  // }
  
  

  getCouponDetails(id: any) {
    this.spinner.show();
    this._adminService.getDealByCouponID(id).pipe(finalize(() => {
      // this.spinner.hide();
      //this.updateCountIp()
    })).subscribe((res: any) => {
      console.log("public", res);
      if (res.statusCode === 200) {
        this.spinner.hide();
        this.dealData = res.deal;
        this.coupon = this.dealData.coupon;
        this.dealContent = this.sanitizer.bypassSecurityTrustHtml(this.dealData.dealContent);
        
        setTimeout(()=> {
          if (!this.isUpdatedIp)
            this.updateCountIp();

        
          if (this.dealData && this.dealData.isRedirect) {
            setTimeout(()=> {
              window.location.href = this.dealData.redirectURL;
            }, 3000);
            
            //window.open(this.dealData.redirectURL,'_blank')
          } 
        }, 8000);
        
        // setTimeout(()=> {
        //   if (!this.isUpdatedIp)
        //     this.updateCountIp();
        // }, 20000);
        // else {
        //   this.modalService.open(this.mymodal, {ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
        //     this.closeResult = `Closed with: ${result}`;
        //   }, (reason) => {
        //     this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        //   });
        // }
        
        
      }
    }, (err)=> {
      this.routerServices.navigateByUrl('/deal/expired-deal');
    });

  }
  skipDeal() {
    this.getCouponDetails(this.couponId);
  }

  getIP()  
  {  
    this._adminService.getIPAddress().subscribe((res:any)=>{  
      this.ipAddress=res.ip;  
      console.log("this.ipAddress", this.ipAddress);
    
        this._adminService.countIp(this.couponId, {"IP":this.ipAddress}).subscribe((result:any)=>{
           console.log("result", result);
         })
    });  
  } 
  updateCountIp() {
    this.isUpdatedIp = true;
    this._adminService.countIp(this.couponId, {"IP": true, "emailId": this.email}).subscribe((result:any)=>{
      console.log("result", result);
    })
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  private validateEmail() 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email))
    {
      return (true)
    }
      alert("You have entered an invalid email address!")
      return (false)
  }
  submit() {
    let status = this.validateEmail();
    if (status) {
      this._adminService.countIp(this.couponId, {"IP": false, "emailId": this.email}).subscribe((result:any)=>{
        console.log("result", result);
        this.result = result;
        console.log(this.result.message);
      })
    }
  }
  getAdvertisements() {
    //this.spinner.show();
    this._adminService.getAdvertisements()
      .subscribe((res: any) => {
        console.log("public", res);
        if (res.statusCode === 200) {
          console.log(res); 
          if (res.advertises && res.advertises.length > 0)
            this.advertismentData = res.advertises[0];
          
        }
    }, (err)=> {
      //this.routerServices.navigateByUrl('/deal/expired-deal');
    });

  }
  oberserableTimer() {
    const source = timer(1000, 2000);
    const abc = source.subscribe(val => {
      console.log(val, '-');
      this.subscribeTimer = this.timeLeft - val;
    });
  }

  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 8;
      }
    },1000)
  }
  clickBuilderContent() {
    if (!this.isUpdatedIp)
      this.updateCountIp();
  }

  pauseTimer() {
    clearInterval(this.interval);
  }


}
