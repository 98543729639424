import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {


  constructor(private http: HttpClient) { }
  getVendorList(role: any, status: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + role + "/approve?status=" + status);
  }

  getActiveVendorList(role: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + role + "/approve?status=active");
  }
  getOfflineVendor(role: any, status: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + role + "/approve?vendorType=offline&status=" + status);
  }
  getOnlineVendorList(role: any, status: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + role + "/approve?vendorType=online&status=" + status);
  }


  pendingApproval(userId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/approve", body);
  }

  rejectApproval(userId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/reject", body);
  }

  getCountryCode() {
    return this.http.get(environment.apiUrl + "/api/earnin/countrycode/");
  }

  addCountry(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/country", body);
  }
  getCountry(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/country");
  }
  editCountry(userId: any, cId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/country/" + cId, body);
  }
  deleteCountry(userId: any, cId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/country/" + cId);
  }

  addCity(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/city", body);
  }
  getAllCities(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/city",);
  }
  updateCity(userId: any, cityId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/city/" + cityId, body);
  }

  deleteCity(userId: any, cityId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/city/" + cityId);
  }

  addCategory(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/category", body);
  }

  getCategory(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/category");
  }

  updateCategory(userId: any, categoryId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/category/" + categoryId, body);
  }

  deleteCategory(userId: any, categoryId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/category/" + categoryId);
  }

  upDateCoins(userId: any, countryId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/country/" + countryId, body);
  }

  getCountryWithCoins(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/country?coin=true");
  }

  addCountryCoins(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/country", body);
  }

  getTax(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/tax");
  }


  addTax(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/tax", body);
  }

  editTax(userId: any, taxId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/tax/" + taxId, body);
  }

  deleteTax(userId: any, taxId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/tax/" + taxId);
  }

  //Question API Start Here

  addQuestions(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/question", body);
  }

  editQuestion(userId: any, qtyId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/question/" + qtyId, body);
  }

  getQuestions(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/questions");
  }


  deleteQuestions(userId: any, questionId: any) {

    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/question/" + questionId);
  }

  addNotification(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/notification/message", body);
  }
  getNotification(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/notification/messages");
  }
  deleteNotification(userId: any, msgId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/notification/message/" + msgId);
  }



  updateNotification(userId: any, rowId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/notification/message/" + rowId, body);
  }

  addCoinSettings(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/coinsetting", body);
  }

  addDefaultCoin(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/defaultcoinsetting", body);
  }

  getCoinData(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/coinsetting");
  }

  getDefaultCoinData(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/defaultcoinsetting");
  }

  deleteDefaultCoin(userId: any, msgId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/defaultcoinsetting/" + msgId);
  }




  editCoinSetting(userId: any, cId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/coinsetting/" + cId, body);
  }

  editDefaultCoinSetting(userId: any, dCoinId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/defaultcoinsetting/" + dCoinId, body);
  }

  // editCoinSettingDefault(userId: any, body: any) {
  //   return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/coinsetting/", body);
  // }

  deleteCoinSetting(userId: any, cId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/coinsetting/" + cId);
  }

  getAllUsers(userId: any, searchName: string, skipCount: number, limit: number, status?: string) {
    if (status)
      return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/search?name=" + searchName + "&skip= " + skipCount + "&limit=" + limit + "&status=" +status);
    else
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/search?name=" + searchName + "&skip= " + skipCount + "&limit=" + limit);
    // return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/search?name=earn");
  }
  getAllPremiumUsers(userId: any, searchName: string, skipCount: number, limit: number, influencerType?: string) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/search?influencerType="+influencerType+"&name=" + searchName + "&skip= " + skipCount + "&limit=" + limit);
  }

  // getWithdrawScreenMsg(userId: any){
  //   return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/info");
  // }

  getUserQstnAnswer(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/qstnanswer");
  }

  getApprove(userId: any, withdId: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/withdraw/" + withdId + "/approve", {});
  }
  getRejectedCol(userId: any, withdId: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/withdraw/" + withdId + "/reject", {});
  }

  getCoinPerBudget(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/budgetcoins", body);
  }

  getDealByCouponID(couponId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/influencer/" + couponId);
  }

  getDealByCouponViews(couponId: any) {
    return this.http.get(environment.apiUrl + "/api/views/" + couponId);
  }

  userBlockAction(userId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/block", body);
  }

  getIPAddress() {
    //return this.http.get("https://secret-ocean-49799.herokuapp.com/http://api.ipify.org/?format=json");
    return this.http.get("http://api.ipify.org/?format=json");
  }

  countIp(couponId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/coupondeal/" + couponId, body);
  }

  userReports(userId: any, body: any, skipCount?: number, limit?: number) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/report?skip="+skipCount+"&limit="+limit, body);
  }

  transactionReports(userId: any, body: any, skipCount?: number, limit?: number, filterBy?: string) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/transaction/report?limit="+limit+"&skip="+skipCount+"&filterBy="+filterBy, body);
  }
  getVendorOfferTransactions(userId: any, body: any, skipCount?: number, limit?: number, role?: any) {
    if (role == 'admin')
      return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/offer/transactions?limit="+limit+"&skip="+skipCount, body);
    else
      return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/offer/transactions?limit="+limit+"&skip="+skipCount, body);
  }

  getUserDataCountryWise(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/"+userId+"/countrywiseuser");
  }
  updateInfluencerCoin(userId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/influencer/coins", body);
  }
  duplicateUsersReports(userId: any, status: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/filterBy?label=" + status);
  }
  getDaywiseDealViewCount(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/deals/views", body);
  }
  getDaywiseDealViewCountForVendors(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/vendors/" + userId + "/deals/views", body);
  }
  getAllDeals(userId: any) {
    let role = localStorage.getItem('role');
    if (role != 'admin') {
      return this.http.get(environment.apiUrl + "/api/earnin/vendors/" + userId + "/deals?isMinimal=true");
    }
    else {
      return this.http.get(environment.apiUrl + "/api/earnin/deals?isMinimal=true");
    }
  }
  getAllActiveDeals(userId: any) {
    let role = localStorage.getItem('role');
    if (role != 'admin') {
      return this.http.get(environment.apiUrl + "/api/earnin/vendors/" + userId + "/deals?isMinimal=true&status=active");
    }
    else {
      return this.http.get(environment.apiUrl + "/api/earnin/deals?isMinimal=true&status=active");
    }
  }
  updateVendorCategory(userId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/vendor/category", body);
  }
  unblockUser(userId: any, param: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/unblock", param);
  }
  blockUser(userId: any, param: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/block", param);
  }
  updateInfluencerDetails(userId: any, data: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/influencer", data);
  }
  getInfluencerCategory(userId: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/influencer/category");
  }
  addInfluencerCategory(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/influencer/category", body);
  }
  updateInfluencerCategory(userId: any, categoryId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/influencer/category/" + categoryId, body);
  }
  deleteInfluencerCategory(userId: any, categoryId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/influencer/category/" + categoryId);
  }
  getAdvertisements() {
    return this.http.get(environment.apiUrl + "/api/earnin/public/advertisements?position=dp");
  }
  getOfflineVendorList(role: any) {
    //return this.http.get(environment.apiUrl + "/api/earnin/users/" + role + "/approve?status=pending&vendorType=offline");
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + role + "/approve?vendorType=offline&status=active");
  }
  getDiscountsData(userId: any, skipCount: any, limit: any) {
    return this.http.get(environment.apiUrl + "/api/earnin/users/" + userId + "/discounts?skip="+skipCount+"&limit="+limit);
  }
  addDiscountsData(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/discounts", body);
  }
  deleteDiscountData(userId: any, discountId: any) {
    return this.http.delete(environment.apiUrl + "/api/earnin/users/" + userId + "/discounts/" + discountId);
  }
  updateDiscountsData(userId: any, discountId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/discounts/"+discountId, body);
  }
  uploadBulkCSV(userId: any, body: any) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/csv/transaction", body);
  }
  updateOfflineVendorStatus(userId: any, status: any, transactionId: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/offer/transactions/" + transactionId, {'status': status});
  }
  pushNotifications(userId: any, body: any, skipCount?: number, limit?: number) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/bellnotification?skip="+skipCount+"&limit="+limit, body);
  }
  fixedBidTransactionReports(userId: any, body: any, skipCount?: number, limit?: number) {
    return this.http.post(environment.apiUrl + "/api/earnin/users/" + userId + "/influencerbiddeal?skip="+skipCount+"&limit="+limit, body);
  }
  approveFixedBidTransaction(userId: any, dealId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/deals/" + dealId + "/approve", body);
  }
  rejectFixedBidTransaction(userId: any, dealId: any, body: any) {
    return this.http.put(environment.apiUrl + "/api/earnin/users/" + userId + "/deals/" + dealId + "/reject", body);
  }


}
